import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const FootballPage = () => (
  <Layout>
    <SEO title="Football" />
    <h1>TRYA DEVILDOG FOOTALL</h1>

    <p>
      TRYA offers youth football for 7-12 year olds through FYFL (Foothills Youth Football League).
      The safety of all our athletes is extremely important, and we
      strictly follow all safety guildlines set out by FYFL and are
      members of USA Heads Up Football and American Youth Football. We strive to
      recruit positive role models to serve as our volunteer coaching staff who
      can guide our athletes not only to compete at a high level, but also to
      focus on character development. Go Devildogs! <strong>#weareTR</strong>
    </p>

    <h2>FOOTBALL FAQ</h2>

    <h4>How old does my child have to be to play football?</h4>
    <p>
      TRYA offers football for players in grades K4-6th. The age guidelines for each
      division follow a grade cutoff of August 31st of the current year.
    </p>
    <p>
      <strong>Fall Flag (K4, K5 1st)</strong>
    </p>
    <p>
      <strong>2G Tackle (1st & 2nd)</strong>
    </p>
    <p>
      <strong>4G Tackle (3rd & 4th)</strong>
    </p>
    <p>
      <strong>6G Tackle (5th & 6th)</strong>
    </p>

    <h4>Are there any weight restrictions?</h4>
    <p>
      TRYA follows the safety guidelines set by FYFL. Players
      above the weight limit for each age division will be assigned a number 90
      or above, which will denote they may not touch the ball during play.
    </p>
    <p>
      <strong>2G Tackle</strong> – 110 lbs
    </p>
    <p>
      <strong>4G Tackle</strong> – 125 lbs
    </p>
    <p>
      <strong>6G Tackle</strong> – 150 lbs
    </p>

    <h4>How much does it cost to play?</h4>
    <p>
      The Registration fee for tackle Football players for the 2024 season is
      $200. TRYA is a non-profit organization and we try to keep costs as low as
      possible for our members. Registration costs will not only go
      toward high qualtiy uniforms, field costs, insurance, ref fees, and
      various other expenses, but will also eliminate the need for any
      individual team fundraising.
    </p>

    <h4>What equipment will my child need?</h4>

    <p>
      Tackle Football: Each player will need to have their own set of shoulder
      pads, white helmet, mouth guard, and cleats. Padded practice pants and
      practice jersey are recommended.
    </p>

    <h4>When are practices and games?</h4>
    <p>
      Practices will vary based on coach and field availability, but they
      typically begin in mid-July and are 3 days a week (usually Mon/Tue/Thu),
      then cut back to 2 days a week once games begin.
    </p>
    <p>
      Game schedules are set by FYFL and are released in late August.
      Typically, the first game is in early September. Games with FYFL are mostly
      played on Saturday. There is a gate fee for game entry. $5 per person. Coaches,
      Athletes, and 12 and under are free. Please note these days and times may change
      year to year, this is only a guideline. The last playoff game is usually in November.
    </p>

    <h4>Will my child have to do any fundraisers?</h4>
    <p>
      We have factored in any additional expenses to our registration costs
      in order to eliminate the need for any individual team fundraisers.
    </p>

    <h4>Who do I contact if I have a problem?</h4>
    <p>
      Any issues related to your team should first be addressed with the coach.
      If you need additional assistance, or have a general question, please
      contact us at{" "}
      <a href="mailto:info.tryasc@gmail.com">info.tryasc@gmail.com</a> or send
      us a message on our{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/groups/345642595590502/"
      >
        facebook page
      </a>
    </p>

    <h4>How can I volunteer?</h4>
    <p>
      We are always looking for motivated volunteers! Please contact us at{" "}
      <a href="mailto:info.tryasc@gmail.com">info.tryasc@gmail.com</a> if you
      are interested in a volunteer oppoortunity. Thank you for your interest!
    </p>
  </Layout>
)

export default FootballPage
